@import url('https://rsms.me/inter/inter.css');

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background: #f0f0f0;
  font-family: 'Inter';
}

a {
  color: black;
}

a {
  pointer-events: all;
  color: black;
  text-decoration: none;
}

svg {
  fill: black;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

canvas {
  opacity: 0;
  touch-action: none;
  animation: fade-in 5s ease 0.5s forwards;
}

input {
  font-family: 'Inter';
  width: 100px;
  border: none;
  background-image: none;
  background-color: transparent;
  color: transparent;
  caret-color: #848484;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-weight: 400;
}

input:focus {
  outline: none;
  box-shadow: none !important;
}
